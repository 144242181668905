import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import LoadingContext from "../../../../contexts/LoadingContext";
import api from "../../../../services/api";
/**
 * 
 * @param {{
 *     onResult: (documents?: any[]) => void
 * }} props 
 * @returns 
 */

const SearchDocuments = (props) => {
    const {loading, setLoading} = useContext(LoadingContext);
    const [documentNumber, setDocumentNumber] = useState("");
    const [searchType, setSearchType] = useState({placeholder: "Digite o CPF", format: "###.###.###-##", mask: "_", checked: "cpf"});

    // ====== METHODS ===========
    const handleChangeType = (e) => {
        if(e.target.value === "cpf"){
            setSearchType({placeholder: "Digite o CPF", format: "###.###.###-##", mask: "_", checked: "cpf"});
        }            
        else if (e.target.value === "number"){
            setSearchType({placeholder: "Digite o Nº da Apólice", checked: "number"});
            setDocumentNumber("");
        }
        else {
            setSearchType({placeholder: "Digite o Nome", checked: "name"});
            setDocumentNumber("");
        }
    }

    const handleChange = ({value}) => {
        setDocumentNumber(value);
    };

    const handleChangeText = (e) => {
        setDocumentNumber(e.target.value.trim());
    }

    const handleSearchKeyDown = event =>{
        // ENTER KEY
        if(event.keyCode == 13) handleSearch();
    };

    const handleSearch = async () => {
        try{
            // Valida se CPF esta completo
            if(searchType.checked === "cpf" && documentNumber.length != 11) return toast.warning("Insira o CPF completo");

            // Impede multiplas buscas simultaneas
            if(loading) return;

            setLoading(true);
            var consult = {};
            // Realiza busca
            if(searchType.checked === "cpf"){
                consult = {url : '/backoffice/policies/list', 
                    data:{
                        documentNumber
                    }
                };
            }
            else if(searchType.checked === "number"){
                consult = {url : '/backoffice/policies/getByNumber', 
                    data:{
                        policyNumber: documentNumber
                    }
                };
            }
            else{
                consult = {url : '/backoffice/policies/getByName', 
                    data:{
                        name: documentNumber
                    }
                };
            }
            const response = await api.post(consult.url, consult.data);
            setLoading(false);
            if(response.status == 200){
                props.onResult(response.data.data.policies);
            }else{
                toast.warning("Não foi possível realizar login. Por favor, tente novamente mais tarde");
            }
        }
        catch(err){
            setLoading(false);
            console.log(err);
            toast.warning("Não foi possível recuperar documentos");
        }
    };
    return (
        <Row>
            <Col sm="12" md="6">
                <InputGroup className={["input-search", loading ? "disabled" : ""]} hasValidation >
                    {(searchType.checked === "cpf" ? 
                    <NumberFormat
                        placeholder={searchType.placeholder}
                        format={searchType.format}
                        mask={searchType.mask}
                        customInput={Form.Control}
                        aria-describedby="inputGroupPrepend"
                        onKeyDown={handleSearchKeyDown}
                        onValueChange={handleChange}
                        disabled={loading}
                        required
                    /> : 
                    <Form.Control
                        maxLength={25}
                        placeholder={searchType.placeholder}
                        onKeyDown={handleSearchKeyDown}
                        onChange={handleChangeText}
                        value={documentNumber}
                        disabled={loading}
                        required
                    />)}
                    <Button onClick={() => handleSearch()} variant="outline-secondary" type="button">
                        <FontAwesomeIcon icon={faSearch} />
                    </Button> 
                </InputGroup>
                <InputGroup>
                    <Form.Check className="radioButon-dashbord" type="radio" checked={searchType.checked === "cpf"} name="optionSearch" value="cpf" label="CPF" onChange={(e) => handleChangeType(e)}/>
                    <Form.Check className="radioButon-dashbord" type="radio" checked={searchType.checked === "number"} name="optionSearch" value="number" label="Nº Apólice" onChange={(e) => handleChangeType(e)}/>
                    <Form.Check className="radioButon-dashbord" type="radio" checked={searchType.checked === "name"} name="optionSearch" value="name" label="Nome" onChange={(e) => handleChangeType(e)}/>
                </InputGroup>
            </Col>

        </Row>

    );
}

export default SearchDocuments;