import { useEffect, useState } from "react";
import { Navbar, Nav, Container, Dropdown, Figure } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Auth from "../../auth";
import Show from "../ShowIf";
import { toast } from "react-toastify";
import api from "../../services/api";
// Assets
import Logo from "../../assets/logo-menor.png";
import MenuIcon from "../../assets/icons/menu.png";
import MenuXIcon from "../../assets/icons/x-menu.png";

const Menu = () => {
  const [sessionData, setSessionData] = useState(false);
  const [iconIsClose, setIconIsClose] = useState(true);
  const [menu, setMenu] = useState();
  const history = useHistory();
  let location = useLocation();
  const width = window.innerWidth;

  useEffect(() => handleSearchMenu(), [sessionData]);
  useEffect(() => setSessionData(Auth.getSession()), [location]);

  const handleSearchMenu = async () => {
    if (sessionData) {
      try {
        const response = await api.get(
          "/backoffice/comum/getMenu?permission=1"
        ); //permissão ainda precisa ser definida corretamente
        if (response.status == 200) {
          setMenu(response.data.data.menus);
        } else {
          toast.warning("Não foi possível criar o menu.");
        }
      } catch (err) {
        console.log(err);
        toast.warning("Não foi possível criar o menu.");
      }
    }
  };

  const handleLogoff = () => {
    Auth.destroySession();
    history.push("/");
  };

  const buildSubmenu = (sub) => {
    return sub?.map((m, i) => (
      <Link className="nav-link" to={"/" + m.url}>
        {m.nome}
      </Link>
    ));
  };

  const buildMenu = () => {
    return menu?.map((m, i) => (
      <>
        <Dropdown
          drop={"down"}
          navbar={true}
          onToggle={(isOpen) => setIconIsClose(!isOpen)}
          align="start"
        >
          <Dropdown.Toggle id="dropdown-menu" align="start">
            <label className="color-mainFont font-bold label-filter">
              {m.topico}
            </label>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-container">
            {buildSubmenu(m.subTopico)}
          </Dropdown.Menu>
        </Dropdown>
      </>
    ));
  };

  return (
    <Navbar collapseOnSelect bg="light" expand="lg" className="menu-principal">
      <Container className="container-menu">
        <Link className="navbar-brand" to="/">
          <Figure>
            <img src={Logo} />
          </Figure>
        </Link>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setIconIsClose(!iconIsClose)}
        >
          {sessionData && width >= 991 ? (
            sessionData.login
          ) : (
            <Figure>
              <img src={iconIsClose ? MenuIcon : MenuXIcon} />
            </Figure>
          )}
        </Navbar.Toggle>
        <Show if={sessionData}>
          <Navbar.Collapse
            expand
            style={{ "margin-left": "30px" }}
            className="menu-left"
            id="responsive-navbar-nav"
          >
            <Nav>{buildMenu()}</Nav>
          </Navbar.Collapse>
        </Show>
        <Navbar.Collapse className="menu-right" id="responsive-navbar-nav">
          <Nav>
             <Show if={!sessionData}>
              <Nav.Link
                target="_blank"
                href="https://www.emotion.com.br/stories"
              >
                Stories
              </Nav.Link>
              <Nav.Link
                target="_blank"
                href="https://www.emotion.com.br/duvidas-frequentes"
              >
                Dúvidas
              </Nav.Link>
              <Nav.Link
                target="_blank"
                href="https://www.emotion.com.br/clientes"
              >
                Clientes
              </Nav.Link>
            </Show>
            {/* Menu Tablet/Desktop */}
              <Dropdown
                drop={"down"}
                navbar={true}
                onToggle={(isOpen) => setIconIsClose(!isOpen)}
              > 
                <Show if={sessionData}>
                <Dropdown.Toggle id="dropdown-menu">
                  {sessionData ? (
                    <span className="color-mainFont font-bold">
                      {sessionData.login}
                    </span>
                  ) : (
                    <Figure className="menu-figure">
                      <img src={iconIsClose ? MenuIcon : MenuXIcon} />
                    </Figure>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-container"> 
                    <Nav.Link onClick={handleLogoff}>Sair</Nav.Link>
                </Dropdown.Menu>
                </Show>
              </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
