import Menu from "../../components/Menu";

const LayoutPage = props => {
    return (
        <main className="bg-bgColor">
            <header>
                <Menu />
            </header>

            <div className="container containerMain">
                {props.children}
            </div>
        </main>
    );
}

export default LayoutPage;