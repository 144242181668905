import React from "react";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import LoadingContext from "../../contexts/LoadingContext";
import useLogged from "../../hooks/useLogged";
import { toast } from "react-toastify";
import api from "../../services/api";
import NumberFormat from "react-number-format";
import { useParams, useHistory } from "react-router-dom";

const CreateTypeService = () => {
  const history = useHistory();
  const { code } = useParams();
  const { loading, setLoading } = useContext(LoadingContext);
  const sessionData = useLogged("/login", false);
  const [reponsibles, setReponsibles] = useState();
  const [formValues, setFormValues] = useState({
    code: "",
    description: "",
    responsible: "",
    unit: "",
    value: "",
    attention: "",
    critical: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    handleSearchResponsibles();
    handleSearchService();
  }, []);

  const handleSearchResponsibles = async () => {
    try {
      setLoading(true);
      const response = await api.get("/backoffice/admin/listResponsibles");
      if (response.status == 200) {
        setReponsibles(response.data.data);
        setFormValues({
          ...formValues,
          responsible: response.data.data[0].descricao,
        });
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar responsáveis. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível encontrar responsáveis");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchService = async () => {
    if (code) {
      try {
        setLoading(true);
        const response = await api.get(
          "/backoffice/admin/getTypeService?code=" + code
        );
        if (response.status == 200) {
          setFormValues({
            code: response.data.data.codigo,
            description: response.data.data.descricao,
            responsible: response.data.data.responsavel,
            unit: response.data.data.unidade,
            value: response.data.data.valor,
            attention: response.data.data.atencao,
            critical: response.data.data.critico,
          });
        } else {
          setLoading(false);
          toast.warning(
            "Não foi possível encontrar tipo de serviço. Por favor, tente novamente mais tarde"
          );
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
        toast.warning("Não foi possível encontrar tipo de serviço");
      } finally {
        setLoading(false);
      }
    }
  };

  //acesso a API
  const handleCreate = async () => {
    for (var fv in formValues) {
      if (formValues.hasOwnProperty(fv)) {
        return toast.warning("Um ou mais campos vazios");
      }
    }
    try {
      setLoading(true);
      const response = await api.post(
        "/backoffice/admin/newTypeService",
        formValues
      );
      if (response.status == 201) {
        toast.success("Serviço cadastrado com sucesso");
        history.push("/type-service-list");
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível cadastrar tipo de serviço. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning(
        "Não foi possível cadastrar tipo de serviço. Por favor, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  };

  const constroiSelect = (resp) => {
    return resp?.map((r) => {
      return <option value={r.descricao}>{r.descricao}</option>;
    });
  };

  return (
    <div>
      <Container>
        <h4 className="mb-4 text-left">
          {code ? "Alterar Tipo de Serviço" : "Cadastrar Tipo de Serviço"}
        </h4>
        <Row className="mb-4">
          <Col sm="11">
            <Form.Label required className="label-filter input-group">
              Código
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={"Código do serviço"}
              value={formValues?.code}
              name="code"
              onChange={handleInputChange}
              required
              maxLength={3}
            />
            <Form.Label className="label-filter input-group">
              Descrição
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={"Descrição do serviço"}
              value={formValues?.description}
              name="description"
              onChange={handleInputChange}
              required
            />
            <Form.Label className="label-filter input-group">
              Responsável
            </Form.Label>
            <Form.Select
              name="responsible"
              className="form-control input-select"
              onChange={handleInputChange}
              value={formValues.responsible}
            >
              {constroiSelect(reponsibles)}
            </Form.Select>
            <Form.Label className="label-filter input-group">
              Unidade SLA
            </Form.Label>
            <Form.Select
              name="unit"
              className="form-control input-select"
              onChange={handleInputChange}
              value={formValues.unit}
            >
              <option value="MIN"> Minuto</option>
              <option value="HR"> Hora</option>
              <option value="DIA"> Dia</option>
              <option value="SEM"> Semana</option>
            </Form.Select>
            <Form.Label className="label-filter input-group">
              Valor SLA
            </Form.Label>
            <NumberFormat
              placeholder={"Unidades para SLA padrão"}
              value={formValues?.value}
              name="value"
              onChange={handleInputChange}
              customInput={Form.Control}
              aria-describedby="inputGroupPrepend"
              disabled={loading}
              required
            />
            <Form.Label className="label-filter input-group">
              Valor de Atenção
            </Form.Label>
            <NumberFormat
              placeholder={"Unidades para SLA atenção"}
              value={formValues?.attention}
              name="attention"
              onChange={handleInputChange}
              customInput={Form.Control}
              aria-describedby="inputGroupPrepend"
              disabled={loading}
              required
            />
            <Form.Label className="label-filter input-group">
              Valor Crítico
            </Form.Label>
            <NumberFormat
              placeholder={"Unidades para SLA crítico"}
              value={formValues?.critical}
              name="critical"
              onChange={handleInputChange}
              customInput={Form.Control}
              aria-describedby="inputGroupPrepend"
              disabled={loading}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 2, offset: 9 }}>
            <Button
              className="btn btn-danger"
              type={"submit"}
              onClick={() => handleCreate()}
            >
              {code ? " Alterar " : " Cadastrar "}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateTypeService;
