import React from 'react';

const Show = (props) => {

    return ( 
        <>
            {(props.if && props.children ? props.children : "")}
        </>
    );
}

export default Show;