import axios from "axios";
import config from "../config.json";

const apiAuth = axios.create({
    baseURL: config.ambiente === "LOCAL" ? config.apiFront : config.apiFront,
    headers: { 
        'Content-Type': 'application/json'
    }
});
// headers: {
//     "x-access-web-token": config.autenticacao.tokenLoja,
//     "x-access-web-secret": config.autenticacao.secretLoja
// }

export default apiAuth;