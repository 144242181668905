// import logo from './logo.svg';
import './styles/tema.scss';
import PagesRoutes from './routes';
import { ToastContainer } from 'react-toastify';
import LoadingContext from './contexts/LoadingContext';
import { useState } from 'react';
import LoadingScreen from './components/LoadingScreen';


const App = () => {
  const [loading, setLoading] = useState(false);
  
  return (
    <LoadingContext.Provider value={{loading, setLoading}}>
      <LoadingScreen show={loading} />
      <PagesRoutes />
      <ToastContainer theme={'colored'}/>
    </LoadingContext.Provider>
  )
};

export default App;
