import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import Moment from "react-moment";
import { toast } from "react-toastify";
import LoadingContext from "../../../contexts/LoadingContext";
import api from "../../../services/api";
import { CSVLink } from "react-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalCards = (props) => {
  const { status, filter, show, handleClose } = props;
  const { setLoading } = useContext(LoadingContext);
  const [list, setList] = useState();
  const columns = [
    { label: "ID", key: "PK" },
    { label: "Status", key: "oppStatus" },
    { label: "Data Criação", key: "oppCreatedAt" },
    { label: "Origem", key: "oppOrigin" },
    { label: "Etapa Atual", key: "oppCurrentStep" },
  ];
  
  useEffect(() => handleSearch(), [status]);

  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        "backoffice/dashboard/getStatusList?status=" +
          status.code +
          "&" +
          filter
      );
      if (response.status == 200) {
        const resultado = response.data.data.opportunities.map((op) => {
          let status, step;
          switch (parseInt(op.oppStatus, 10)) {
            case 0:
              status = "Em Onboarding";
              break;
            case 10:
              status = "Onboarding finalizado, pronto para o Underwriting";
              break;
            case 20:
              status = "Em Underwriting";
              break;
            case 30:
              status = "Underwriting finalizado com sucesso";
              break;
            case 70:
              status = "Em processo de criação da conta";
              break;
            case 80:
              status = "Criação de conta finalizada com sucesso";
              break;
            case 99:
              status = "Erro no underwriting";
              break;
          }
          switch (parseFloat(op.oppCurrentStep)) {
            case 0:
              step = "Em recontratação";
              break;
            case 1:
              step =
                op.oppOrigin === "MOBILE"
                  ? "Passou da tela do CPF/E-mail"
                  : "Passou da tela do CPF/E-mail/Celular/Senha";
              break;
            case 2:
              step = "Passou da tela de validar o código";
              break;
            case 3:
              step = "Passou da tela do celular";
              break;
            case 4:
              step = "Passou da tela da senha";
              break;
            case 4.5:
              step = "Passou da tela do cupom";
              break;
            case 5:
              step = "Passou da tela do cartão";
              break;
          }
          return { ...op, oppStatus: status, oppCurrentStep: step };
        });
        setList(resultado);
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar as oportunidades. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível encontrar as oportunidades.");
    } finally {
      setLoading(false);
    }
  };

  //cria cabeçalho da tabela
  const headerTable = (header) => {
    return header.map((h) => {
      return <th>{h.label}</th>;
    });
  };

  //cria celular da tabela
  const cellsTable = (cells) => {
    return cells
      ?.sort((a, b) =>
        moment(a.oppCreatedAt).isSameOrBefore(b.oppCreatedAt) ? -1 : 1
      )
      .map((c) => {
        return (
          <tr>
            <td>{c.PK}</td>
            <td>{c.oppStatus}</td>
            <td>
              <Moment format="DD/MM/YYYY HH:mm:ss" date={c.oppCreatedAt} />
            </td>
            <td>{c.oppOrigin}</td>
            <td>{c.oppCurrentStep}</td>
          </tr>
        );
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{status.description}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-bgColor">
        <Row className="justify-content-md-right">
          <Col md={{ span: 2, offset: 9 }}>
            <CSVLink
              onClick={() => {
                if (list == null) {
                  toast.warning(
                    "Lista não possui oportunidades disponíveis para fazer download"
                  );
                }
              }}
              className={"btn btn-danger" + (list == null ? " disabled" : "")}
              filename={"relatorio-oportunidades.csv"}
              data={list || ""}
              headers={columns}
            >
              <FontAwesomeIcon icon={faDownload} /> Download .CSV
            </CSVLink>
          </Col>
        </Row>
        <Table striped borderless hover size="md">
          <thead>
            <tr>{headerTable(columns)}</tr>
          </thead>
          <tbody>{cellsTable(list)}</tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCards;
