import React from "react";
import { useContext, useEffect, useState } from "react";
import LoadingContext from "../../contexts/LoadingContext";
import useLogged from "../../hooks/useLogged";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Col, Form, Row } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

const CreateService = () => {
  //inicializadores do componente
  const { loading, setLoading } = useContext(LoadingContext);
  const { documentNumber } = useParams();
  const sessionData = useLogged("/login");
  const [service, setService] = useState();
  const [policies, setPolicies] = useState();
  const [formValues, setFormValues] = useState({});
  const [types, setTypes] = useState();
  const history = useHistory();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  //função de controle da tabela
  useEffect(() => handleSearch(), []);

  //acesso a API
  const handleSearch = async () => {
    try {
      if (loading) return;
      setLoading(true);
      //recupera policy holder
      const policyholder = await api.post(
        "/backoffice/policy/getPolicyHolder",
        { documentNumber: documentNumber }
      );
      if (policyholder.status === 200) {
        const policylist = await api.post("/backoffice/policies/list", {
          documentNumber: documentNumber,
        });
        if (policylist.status === 200) {
          const policy = policylist.data.data.policies.find(
            (obj) => obj.status === "VIG"
          );
          setPolicies(policy);
          const atendimento = await api.post(
            "/backoffice/customerService/newService",
            {
              documentNumber: documentNumber,
              policyNumber: policy?.number,
            }
          );
          if (atendimento.status === 201) {
            setService(atendimento.data.data);
            const tipos = await api.get("/backoffice/admin/listTypeServices");
            if (tipos.status === 200) {
              setTypes(tipos.data.data);
              setFormValues({...formValues, type : tipos.data.data[0]?.codigo })
            } else {
              setLoading(false);
              toast.warning(
                "Não foi possível gerar protocolo. Por favor, tente novamente mais tarde"
              );
              history.push("/customer-service");
            }
          } else {
            setLoading(false);
            toast.warning(
              "Não foi possível gerar protocolo. Por favor, tente novamente mais tarde"
            );
            history.push("/customer-service");
          }
        } else {
          setLoading(false);
          toast.warning(
            "Não foi possível encontrar apólices. Por favor, tente novamente mais tarde"
          );
          history.push("/customer-service");
        }
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar titular com o CPF informado. Por favor, tente novamente mais tarde"
        );
        history.push("/customer-service");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning(
        "Não foi possível gerar protocolo. Por favor, tente novamente mais tarde"
      );
      history.push("/customer-service");
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async () => {
    for (var fv in formValues) {
      if (formValues.hasOwnProperty(fv)) {
        return toast.warning("Um ou mais campos vazios");
      }
    }
    try {
      setLoading(true);
      //recupera policy holder
      const policyholder = await api.post(
        "/backoffice/customerService/updateService",
        {
          protocol: service?.protocolo,
          type: formValues.type,
          details: formValues.details,
          contactMobile: formValues.contactMobile,
          contactName: formValues.contactName,
          status: "Aberto",
        }
      );
      if (policyholder.status === 201) {
        toast.success("Cadastro do atendimento realizado com sucesso");
        history.push("/service-details/" + service?.protocolo);
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar titular com o CPF informado. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning(
        "Não foi possível gerar protocolo. Por favor, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  };

  const constroiSelect = (resp) => {
    return resp?.map((r) => {
      return (
        <option value={r.codigo}>
          {r.descricao}
        </option>
      );
    });
  };
  //html
  return (
    <div>
      <Row className="mb-2">
        <h3>Cadastro de Atendimento</h3>
      </Row>
      <Row className="mb-5">
        <Col sm="12">
          <ul className="list-unstyled">
            <li>
              <strong>Protocolo:</strong> {service?.protocolo}
            </li>
            <li>
              <strong>CPF:</strong>{" "}
              <NumberFormat
                displayType={"text"}
                format="###.###.###-##"
                value={service?.cpf}
              />
            </li>
            <li>
              <strong>Número da apólice:</strong> {policies?.number}
            </li>
          </ul>
          <Form.Select
            name="type"
            className="form-control input-select"
            onChange={handleInputChange}
            value={formValues.type}
          >
            {constroiSelect(types)}
          </Form.Select>
          <Form.Label className="label-filter input-group">
            Nome Contatante
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={"Nome do Contatante"}
            value={formValues?.contactName}
            name="contactName"
            onChange={handleInputChange}
            required
          />
          <Form.Label className="label-filter input-group">
            Telefone Contatante
          </Form.Label>
          <NumberFormat
            placeholder={"Telefone do Contatante"}
            value={formValues?.contactMobile}
            name="contactMobile"
            format={"(##)#####-####"}
            onChange={handleInputChange}
            customInput={Form.Control}
            aria-describedby="inputGroupPrepend"
            required
          />
          <Form.Label className="label-filter input-group">
            Detalhes do Atendimento
          </Form.Label>
          <Form.Control
            as="textarea"
            className="form-control input-select"
            rows={5}
            value={formValues?.details}
            name="details"
            onChange={handleInputChange}
            required
          />
        </Col>
       
        <Col md={{ span: 2, offset: 10 }}>
          <a
            className="btn btn-danger link-button-list"
            type={"submit"}
            onClick={() => handleCreate()}
          >
            Finalizar Cadastro
          </a>
        </Col>
      </Row>
    </div>
  );
};
export default CreateService;
