import { faFileAlt, faFileDownload, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useContext, useEffect, useState } from "react"
import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import TitleLine from "../../components/TitleLine";
import LoadingContext from "../../contexts/LoadingContext";
import useLogged from "../../hooks/useLogged";
import api from "../../services/api";
import { formatarCelular, formatarCPF, idadeCompleta, statusSeguro } from "../../utils";
import ModalCoverage from "./components/ModalCoverage";
import Stories from "./components/Stories";
import Show from "../../components/ShowIf";

const DetailsPage = () => {

    const [document, setDocument] = useState({});
    const [policyHistory, setPolicyHistory] = useState([]);
    const [modalCoverage, setModalCoverage] = useState(false);

    const {setLoading} = useContext(LoadingContext);

    const {documentNumber, policy} = useParams();
    const history = useHistory();

    const sessionData = useLogged("/login", false);

    // Init
    useEffect(async () => {
        window.scrollTo(0, 0);

        try{
            setLoading(true);

            // ======= Detalhes do seguro ============
            const response = await api.post('/backoffice/policy/details',{
                documentNumber: documentNumber,
                policyNumber: policy
            });

            // Tratativa de requests
            if(response.status == 200){
                setDocument(response.data.data.policies)
            }else{
                toast.warning("Não foi possível recuperar o seguro. Por favor, tente novamente mais tarde");
                return history.push("/search-policies");
            }

            // ======= Histórico do seguro ============
            try{
                const responseHis = await api.post('/backoffice/policy/getPolicyHistory', {
                    policyNumber: policy
                });

                if(responseHis.status == 200){
                    setPolicyHistory(responseHis.data.data);
                }
            }catch{
                setLoading(false);
            }finally{
                setLoading(false);
            }
            
            
        }catch(err){
            console.log(err);
            setLoading(false);
            toast.warning("Não foi possível recuperar histórico do seguro. Por favor, tente novamente mais tarde");
        }
    }, []);

    // ============ METHODS =========================

    /**
     * Busca apólice
     */    
     const downloadPolicy = async () => {
        try {
            setLoading(true);

            const response = await api.post('/backoffice/policy/getPdf', {
                documentNumber: documentNumber,
                policyNumber: policy
            }, {cache: false, responseType: 'arraybuffer'});

            setLoading(false);

            if (response.status == 200) {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            } else {
                toast.warning("Apólice indisponível, por favor tente novamente mais tarde");
            };
        } catch (err) {
            setLoading(false);
            toast.warning("Apólice indisponível, por favor tente novamente mais tarde");
        }        
    }

    const downloadCancellingDocument = async () => {
        try {
            setLoading(true);

            const response = await api.post('/backoffice/policy/getPdfCancellationDocument', {
                documentNumber: documentNumber,
                policyNumber: policy
            }, {cache: false, responseType: 'arraybuffer'});

            setLoading(false);

            if (response.status == 200) {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            } else {
                toast.warning("Documento de cancelamento indisponível, por favor tente novamente mais tarde");
            };
        } catch (err) {
            setLoading(false);
            toast.warning("Documento de cancelamento indisponível, por favor tente novamente mais tarde");
        }        
    }

    /**
     * Busca arquivo de apólice do histórico
     * @param {*} fileName 
     */
    const handlePolicyHistoryDownload = async fileName => {
        try {
            setLoading(true);

            const response = await api.post('/backoffice/policy/history/getPdf', {
                policyNumber: policy,
                fileName: fileName
            }, {cache: false, responseType: 'arraybuffer'});
            if (response.status == 200) {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            } else {
                toast.warning("Apólice indisponível, por favor tente novamente mais tarde");
            };

            setLoading(false);

        } catch (err) {
            setLoading(false);
            toast.warning("Apólice indisponível, por favor tente novamente mais tarde");
        }        
    }

    const openModalCoverage = coverage => setModalCoverage(coverage);


    const showModalCoverage = () => {
        return modalCoverage ? <ModalCoverage coverage={modalCoverage} show={modalCoverage != false} handleClose={()=>setModalCoverage(false)} /> : "";
    }
    // ============ BUILDERS ========================

    /**
     * Cria lista de faturas
     * @returns 
     */
    const buildInvoices = () => {
        return <Accordion defaultActiveKey="0">
            { document.invoices?.map( f => <Accordion.Item eventKey="0">
                <Accordion.Header className="bg-mainFont-button">{f.period} - {f.status}</Accordion.Header>
                <Accordion.Body>
                    <Row className="mb-4">
                        <Col><strong>Prêmio NET:</strong> <NumberFormat displayType={"text"} prefix="R$" value={f.premiumNet} decimalSeparator="," /></Col>
                        <Col><strong>Prêmio Total:</strong> <NumberFormat displayType={"text"} prefix="R$" value={f.totalPremium} decimalSeparator="," /></Col>
                    </Row>
                    <Row className="mb-4">
                        <Col><strong>IOF:</strong> <NumberFormat displayType={"text"} prefix="R$" value={f.iofPremium} decimalSeparator="," /> </Col>
                        <Col><strong>Expira em:</strong> <Moment format="DD/MM/YYYY HH:mm:ss" date={f.expirationDate} /> </Col>  
                    </Row>
                    <Row>
                        <Col>
                            <h2 className="menor">Serviços</h2>
                            <Row>
                                { buildServices(f.coverages) }
                            </Row>
                        </Col>
                        <Col>
                            <h2 className="menor">Coberturas</h2>
                            <Row>
                                { buildCoverages(f.coverages) }
                            </Row>
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>)
            }
        </Accordion>
    }

    /**
     * Cria lista de coberturas
     * @param {any[]} coverages Lista de coberturas em objeto 
     * @returns 
     */
    const buildCoverages = coverages => {
        return coverages?.filter(c => c.type == "SEG").map((c,i) =>
            <Col sm="12" key={'cob-'+i+'-'+c.name.charAt(0)}>
                <Card className="">
                    <Card.Header className="h3 bg-main text-center">{c.name}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <Row>
                                <Col><strong>Prêmio NET:</strong> <NumberFormat displayType={"text"} prefix="R$" value={c.premiumNet} decimalSeparator="," /></Col>
                                <Col><strong>Prêmio Total:</strong> <NumberFormat displayType={"text"} prefix="R$" value={c.totalPremium} decimalSeparator="," /></Col>
                            </Row>
                            <Row>
                                <Col><strong>IOF:</strong> <NumberFormat displayType={"text"} prefix="R$" value={c.iofPremium} decimalSeparator="," /> </Col>
                            </Row>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col>
                                <a onClick={()=> openModalCoverage(c)}>
                                    <FontAwesomeIcon icon={faHistory} /> Detalhes
                                </a>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>)
    }

    /**
     * Cria lista de serviços
     * @param {any[]} coverages Lista de coberturas em objeto 
     * @returns 
     */
     const buildServices = coverages => {
        return coverages?.filter(c => c.type == "SVC").map((c,i) =>
            <Col sm="12" key={'serv-'+i+'-'+c.name.charAt(0)}>
                <Card className="">
                    <Card.Header className="h3 bg-main text-center">{c.name}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <Row>
                                <Col><strong>Prêmio NET:</strong> <NumberFormat displayType={"text"} prefix="R$" value={c.premiumNet} decimalSeparator="," /></Col>
                                <Col><strong>Prêmio Total:</strong> <NumberFormat displayType={"text"} prefix="R$" value={c.totalPremium} decimalSeparator="," /></Col>
                            </Row>
                            <Row>
                                <Col><strong>IOF:</strong> <NumberFormat displayType={"text"} prefix="R$" value={c.iofPremium} decimalSeparator="," /> </Col>
                            </Row>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col>
                                <a onClick={()=> openModalCoverage(c)}>
                                    <FontAwesomeIcon icon={faHistory} /> Detalhes
                                </a>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>)
    }

    const buildHistory = () => {
        return policyHistory
            .sort((a,b) => moment(a.lastModified).isAfter(moment(b.lastModified)) ? -1 : 1)
            .map((h,i) => 
                <li key={'his-'+i+'-'+h.fileName.charAt(0)} className="list-group-item">
                    <a onClick={() => handlePolicyHistoryDownload(h.fileName)} alt={h.fileName} ><FontAwesomeIcon icon={faFileDownload} /> {h.fileName} - <Moment date={h.lastModified} format="DD/MM/YYYY HH:mm:ss" /></a>
                </li>)
    }

    const buildCoverageList = () => {
        return (
            <ul className="list-group list-group-flush">
                {document.coverages?.map( c => {
                    return (
                        <li className="list-group-item">
                            <Row>
                                <Col>
                                    <h2 className="menor">{c.name}</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col><strong>Segurado:</strong> {c.insuredSum}</Col>
                                <Col><strong>Prêmio Estimado :</strong> {c.periodEstimatedPremium}</Col>
                            </Row>
                        </li>
                    )
                })}
            </ul>
        );
    }

    const buildBeneficiaries = () => {
        return (
            <ul className="list-group">
                {document.beneficiaries?.map( b => {
                    return (
                        <li className="list-group-item">
                            <Row>
                                <Col>
                                    <h2 className="menor">{b.name}</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col><strong>Seguro:</strong> {b.percentage}%</Col>
                            </Row>
                        </li>
                    )
                })}
            </ul>
        );
    }

    return (
        <Container>
            {/* MODAL DE COBERTURAS */}
            {showModalCoverage()}

            <Card className="seguroCardDetails mb-5">
                <Card.Header>
                    <Row>
                        <Col md="5">
                            <h3>Seguro nº</h3>
                            <h1 className="text-left color-white">{document.policy?.number}</h1>
                        </Col>
                        <Col>
                            <h3>
                                Início de vigência:
                            </h3>
                            <Moment format="DD/MM/YYYY HH:mm:ss" date={document.policy?.beginEffectiveDate} />
                        </Col>
                        <Col>
                            <h3>
                                Fim de vigência: 
                            </h3>
                            <Moment format="DD/MM/YYYY HH:mm:ss" date={document.policy?.endEffectiveDate} />
                        </Col>
                        <Col>
                            <h3>
                                Status: 
                            </h3>
                            {statusSeguro(document.policy?.status)}
                        </Col>
                    </Row>

                </Card.Header>
                <Card.Body>
                    {/* ======= SEGURADO ========= */}
                    <section className="mb-5">
                        <TitleLine>Segurado</TitleLine>
                        <div  className="mb-4">
                            <Row>
                                <Col><strong>Nome:</strong> {document.policyHolder?.name} </Col>
                                <Col><strong>Email:</strong> {document.policyHolder?.email} </Col>
                            </Row>
                            <Row >
                                <Col><strong>CPF:</strong> {formatarCPF(document.policyHolder?.documentNumber)} </Col>
                                <Col><strong>Idade:</strong> {idadeCompleta(document.policyHolder?.dateOfBirth)} </Col>
                            </Row>
                            <Row >
                                <Col><strong>Telefone:</strong> {formatarCelular(document.policyHolder?.mobile)} </Col>
                            </Row>
                        </div>

                        {/* ENDEREÇO */}
                        { !document.policyHolder?.address
                            ? '' 
                            :
                            <div className="mb-4">
                                <h2 className="menor">Endereço</h2>
                                <Row>
                                    <Col><strong>Logradouro:</strong> {document.policyHolder?.address.address}</Col>
                                    <Col><strong>Bairro:</strong> {document.policyHolder?.address.district}</Col>
                                    <Col><strong>Número:</strong> {document.policyHolder?.address.number}</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Cidade:</strong> {document.policyHolder?.address.city}</Col>
                                    <Col><strong>Estado:</strong> {document.policyHolder?.address.state}</Col>
                                    <Col><strong>CEP:</strong> {document.policyHolder?.address.postalCode}</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Complemento:</strong> {document.policyHolder?.address.complement}</Col>
                                </Row>
                            </div>
                        }
                        
                        {/* FORMA PAGAMENTO */}
                        
                        {(document.paymentData !== null ?
                            <>
                                <h2 className="menor">Forma Pagamento</h2>
                                <Row>
                                    <Col><strong>Método:</strong> {document.paymentData?.method}</Col>
                                    <Col><strong>Nome:</strong> {document.paymentData?.creditcardName}</Col>
                                    <Col><strong>Número:</strong> {document.paymentData?.creditcardNumber}</Col>
                                </Row>
                            </>: null
                        )}
                        {(document.coupon !== null ?
                            <>
                                <h2 className="menor">Cupom</h2>
                                <Row>
                                    <Col><strong>Código: </strong> {document.coupon?.code}</Col>
                                    <Col><strong>Descrição: </strong>  {document.coupon?.description}</Col>
                                </Row>
                            </>: null
                        )}
                    </section>

                    {/* ======= COBERTURAS ========= */}
                    <section className="mb-5">
                        <TitleLine>Coberturas</TitleLine>
                        { buildCoverageList() }                            
                    </section>

                    {/* ======= BENEFICIARIOS ========= */}
                    <section className="mb-5">
                        <TitleLine>Beneficiários</TitleLine>
                        { buildBeneficiaries() }                            
                    </section>
                    
                    {/* ======= FATURAS ========= */}
                    <section className="mb-5">
                        <TitleLine>Faturas</TitleLine>
                        { buildInvoices() }
                    </section>

                    {/* ======= STORIES ========= */}
                    <section className="mb-5">
                        <TitleLine>Stories</TitleLine>
                        <Stories data={document?.stories} />
                    </section>

                    {/* ======= APÓLICE ======= */}
                    <section className="mb-5">
                        <TitleLine>Apólice</TitleLine>
                        <Button onClick={downloadPolicy}>
                            <FontAwesomeIcon icon={faFileAlt} className="me-1" /> Última apólice
                        </Button>
                    </section>

                    {/* ======= HISTÓRICO ======= */}
                    <section className="mb-5">
                        <TitleLine>Histórico de apólices</TitleLine>
                        <div className="overflow-auto mh-100" >
                            <ul className="list-group list-group-flush">
                                { buildHistory() }
                            </ul>
                        </div>
                    </section>
                    <Show if={document.policy?.status === "CAN"}>
                        {/* ======= PDF Cancelamento ======= */}
                        <section className="mb-5">
                            <TitleLine>Cancelamento</TitleLine>
                            <Button onClick={downloadCancellingDocument}>
                                <FontAwesomeIcon icon={faFileAlt} className="me-1" /> Documento de Cancelamento
                            </Button>
                        </section>
                    </Show>
                    
                </Card.Body>
                
            </Card>
            
        </Container>
    )
}

export default DetailsPage;