import { Col, Modal, Row } from "react-bootstrap";
import Moment from "react-moment";
import NumberFormat from "react-number-format";


const ModalCoverage = props => {

    const { coverage, show, handleClose } = props;

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{coverage.name}</Modal.Title>
            </Modal.Header>
        
            <Modal.Body className="bg-bgColor">
                {coverage.coverageHistory?.sort((a,b)=> a.beginEffectiveDate > b.beginEffectiveDate ? -1 : 1 )
                    .map((h,i) => {
                    return (
                        <section className="mb-4 bg-white" key={i}>
                            <h3 className="bg-main p-3 color-white">
                                <Row>
                                    <Col md="7" sm="6">
                                        <Moment format="DD/MM/YYYY HH:mm:ss" date={h.beginEffectiveDate} /> à <Moment format="DD/MM/YYYY HH:mm:ss" date={h.EndEffectiveDate} />
                                    </Col>
                                    <Col className="text-end">
                                        {coverage.type == 'SVC' ? h.InsuredSum+' GB'
                                            :  <NumberFormat
                                            displayType="text"
                                            value={h.InsuredSum}
                                            prefix="R$"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale />
                                        }
                                       
                                    </Col>
                                </Row>
                            </h3>
                            <div className="p-3">
                                <Row>
                                    <Col>
                                        <strong>Prêmio Total: </strong>
                                        <NumberFormat
                                            displayType="text"
                                            value={h.TotalPremium}
                                            prefix="R$"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale />
                                    </Col>
                                    <Col>
                                        <strong>Prêmio Líquido: </strong>
                                        <NumberFormat
                                            displayType="text"
                                            value={h.LiquidPremium}
                                            prefix="R$"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>IOF: </strong>
                                        <NumberFormat
                                            displayType="text"
                                            value={h.IOFPremium}
                                            prefix="R$"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale />
                                    </Col>
                                </Row>
                            </div>
                        </section>
                    )
                })}
            </Modal.Body>
        </Modal>
    );
}

export default ModalCoverage;