import { faBox, faComment, faCommentAlt, faFile, faImage, faTag, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Badge, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import NumberFormat from "react-number-format";


const Stories = props => {

    const { data } = props;

    return (
        <section>
            {/* BENEFICIARIOS */}
            <div className="mb-4">
                <h2 className="menor">Meus amores</h2>

                <Accordion>
                    {data?.beneficiaries?.sort((a,b)=>a.name > b.name ? 1 : -1)
                    .map((b,i) => {
                        return (
                            <Accordion.Item eventKey={i} className="mb-3">
                                <Accordion.Header className="bg-main-button">
                                    <Row className="w-100">
                                        <Col>
                                            <h3 className="color-white">{b.name}</h3>
                                        </Col>
                                        <Col className="text-end me-4">
                                            <Badge bg="secondary" className="fs-6">{b.entries?.length}</Badge>
                                        </Col>
                                    </Row>
                                </Accordion.Header>
                                <Accordion.Body className="bg-bgColor">
                                    <Row>
                                        {b.entries?.sort((a,b) => a.entryDate > b.entryDate ?  1 : -1)
                                        .map((e,i) => {
                                            return (
                                                <Col md="6" sm="12" key={i} className="mb-4">
                                                    <div className="bg-white p-2">
                                                        <div className="mb-3">
                                                            <h4 className="mb-2"><FontAwesomeIcon icon={faBox} /> Armazenado em <strong className="font-bold"><Moment format="DD/MM/YYYY HH:mm:ss" date={e.entryDate} /></strong></h4>
                                                            <h5 className="tag"><span className="h5 color-white"><FontAwesomeIcon icon={faTag} /></span> {e.tag}</h5>
                                                        </div>
                                                        <hr/>
                                                        <Row className="color-mainFont fs-5 text-center">
                                                            <Col>
                                                                <FontAwesomeIcon icon={faImage} /> <strong className="color-main">{e.thread.images}</strong>
                                                            </Col>
                                                            <Col>
                                                                <FontAwesomeIcon icon={faVideo} /> <strong className="color-main">{e.thread.videos}</strong>
                                                            </Col>
                                                            <Col>
                                                                <FontAwesomeIcon icon={faFile} /> <strong className="color-main">{e.thread.documents}</strong>
                                                            </Col>
                                                            <Col>
                                                                <FontAwesomeIcon icon={faCommentAlt} /> <strong className="color-main">{e.thread.messages}</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </div>
            {/* CAIXAS */}
            <div>
                <h2 className="menor">Caixas Especiais</h2>

                <Accordion>
                    {!data ? "" : Object.keys(data).filter(c => c != "beneficiaries")
                    .map((c,i) => {
                        const b = data[c];
                        return (
                            <Accordion.Item eventKey={i} flush>
                                <Accordion.Header className="bg-main-button">
                                    <Row className="w-100">
                                        <Col>
                                            <h3 className="color-white text-capitalize">{c}</h3>
                                        </Col>
                                        <Col className="text-end me-4">
                                            <Badge bg="secondary" className="fs-6">{b.length}</Badge>
                                        </Col>
                                    </Row>
                                </Accordion.Header>
                                <Accordion.Body className="bg-bgColor">
                                    <Row>
                                        {b.sort((a,b) => a.entryDate > b.entryDate ?  1 : -1)
                                        .map((e,i) => {
                                            return (
                                                <Col md="6" sm="12" key={i} className="mb-4">
                                                    <div className="bg-white p-2">
                                                        <div className="mb-3">
                                                            <h4 className="mb-2"><FontAwesomeIcon icon={faBox} /> Armazenado em <strong className="font-bold"><Moment format="DD/MM/YYYY HH:mm:ss" date={e.entryDate} /></strong></h4>
                                                            <h5 className="tag"><span className="h5 color-white"><FontAwesomeIcon icon={faTag} /></span> {e.tag}</h5>
                                                        </div>
                                                        <hr/>
                                                        <Row className="color-mainFont fs-5 text-center">
                                                            <Col>
                                                                <FontAwesomeIcon icon={faImage} /> <strong className="color-main">{e.thread.images}</strong>
                                                            </Col>
                                                            <Col>
                                                                <FontAwesomeIcon icon={faVideo} /> <strong className="color-main">{e.thread.videos}</strong>
                                                            </Col>
                                                            <Col>
                                                                <FontAwesomeIcon icon={faFile} /> <strong className="color-main">{e.thread.documents}</strong>
                                                            </Col>
                                                            <Col>
                                                                <FontAwesomeIcon icon={faCommentAlt} /> <strong className="color-main">{e.thread.messages}</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </div>
        </section>
    );
}

export default Stories;