import React from "react";
import { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import FilterBar from "../../../components/Filter";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//função principal (componente)
const Filter = (props) => {
  //inicializadores do componente
  const [formValues, setFormValues] = useState({
    start: {
      value: moment().subtract(7, "days").format("YYYY-MM-DD"),
      error: "",
    },
    end: { value: moment().add(1, "days").format("YYYY-MM-DD"), error: "" },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: { value: value, error: "" } });
  };

  //controle do periodo
  const handlePeriodRange = (range) => {
    props.setProps("period=" + range);
    setFormValues({
      start: {
        value: moment().subtract(range, "days").format("YYYY-MM-DD"),
        error: "",
      },
      end: { value: moment().add(1, "days").format("YYYY-MM-DD"), error: "" },
    });
  };

  //controle dos filtros
  const createFilter = (e) => {
    e.preventDefault();
    if (
      moment(formValues.start.value).isValid() &&
      moment(formValues.end.value).isValid()
    ) {
      if (
        moment(formValues?.start.value).isSameOrBefore(formValues?.end.value)
      ) {
        props.setProps(
          "start=" +
            moment(formValues.start.value).format("YYYY-MM-DD") +
            "&end=" +
            moment(formValues.end.value).format("YYYY-MM-DD")
        );
      } else {
        toast.warning("Data final do filtro é menor do que data inicial");
      }
    } else {
      toast.warning("Data(s) Inválida(s)");
    }
  };

  //html
  return (
    <FilterBar>
      <Row className="mb-2">
        <Col sm={3}>
          <Form.Label className="label-filter">Data Inicio</Form.Label>
          <Form.Control
            type="date"
            value={formValues.start.value}
            name="start"
            onChange={handleInputChange}
          />
        </Col>
        <Col sm={3}>
          <Form.Label className="label-filter">Data Fim</Form.Label>
          <Form.Control
            type="date"
            value={formValues.end.value}
            name="end"
            onChange={handleInputChange}
          />
        </Col>
        <Col>
          <Row>
            <Col sm={2}>
              <Button
                className="search-button"
                onClick={createFilter}
                variant="outline-secondary"
                type="button"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>

            <Col sm={5}>
              <DropdownButton
                variant="danger"
                title="Filtros por Periodo"
                drop="start down"
                className="link-button-list"
              >
                <Dropdown.Item onClick={() => handlePeriodRange(7)}>
                  7 Dias
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handlePeriodRange(15)}>
                  15 Dias
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handlePeriodRange(30)}>
                  30 Dias
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </FilterBar>
  );
};

export default Filter;
