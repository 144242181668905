import React from "react";
import { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import { faSearch, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterBar from "../../../components/Filter";

//função principal (componente)
const Filter = (props) => {
  const { setFilter, filter, document, columns, createLog } = props;
  const [formValues, setFormValues] = useState({
    start: {
      value: moment().subtract(0, "days").format("YYYY-MM-DD"),
      error: "",
    },
    end: { value: moment().add(1, "days").format("YYYY-MM-DD"), error: "" },
  });
  const [checkboxValue, setCheckboxValue] = useState({
    PAGO: false,
    ABERTO: false,
    FECHADO: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: { value: value, error: "" } });
  };

  //controle do periodo
  const handlePeriodRange = (range) => {
    setFilter({ date: "period=" + range, status: filter.status });
    setFormValues({
      start: {
        value: moment().subtract(range, "days").format("YYYY-MM-DD"),
        error: "",
      },
      end: { value: moment().add(1, "days").format("YYYY-MM-DD"), error: "" },
    });
  };
  //controle check status
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxValue({ ...checkboxValue, [name]: checked });
    let string = "",
      check = { ...checkboxValue, [name]: checked };

    for (const prop in check) {
      if (check[prop] == true) string += "&status[]=" + prop;
    }
    setFilter({ date: filter.date, status: string });
  };

  //controle dos filtros
  const createFilter = (e) => {
    e.preventDefault();
    if (
      moment(formValues.start.value).isValid() &&
      moment(formValues.end.value).isValid()
    ) {
      if (
        moment(formValues?.start.value).isSameOrBefore(formValues?.end.value)
      ) {
        setFilter({
          date:
            "start=" +
            moment(formValues.start.value).format("YYYY-MM-DD") +
            "&end=" +
            moment(formValues.end.value).format("YYYY-MM-DD"),
          status: filter.status,
        });
      } else {
        toast.warning("Data final do filtro é menor do que data inicial");
      }
    } else {
      toast.warning("Data(s) Inválida(s)");
    }
  };
  //html
  return (
    <FilterBar>
      <Row className="mb-2">
        <Col sm={3}>
          <Form.Label className="label-filter">Data Inicio</Form.Label>
          <Form.Control
            type="date"
            value={formValues.start.value}
            name="start"
            onChange={handleInputChange}
          />
        </Col>
        <Col sm={3}>
          <Form.Label className="label-filter">Data Fim</Form.Label>
          <Form.Control
            type="date"
            value={formValues.end.value}
            name="end"
            onChange={handleInputChange}
          />
        </Col>
        <Col>
          <Row>
            <Col sm={2}>
              <Button
                className="search-button link-button-list"
                onClick={createFilter}
                variant="outline-secondary"
                type="button"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>

            <Col sm={5}>
              <DropdownButton
                variant="danger"
                title="Filtros por Periodo"
                drop="start down"
                className="link-button-list"
              >
                <Dropdown.Item onClick={() => handlePeriodRange(0)}>
                  1 Dias
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handlePeriodRange(7)}>
                  7 Dias
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handlePeriodRange(30)}>
                  30 Dias
                </Dropdown.Item>
              </DropdownButton>
            </Col>
            <Col sm={1}>
              <CSVLink
                onClick={() => {
                  if (document == null) {
                    toast.warning(
                      "Lista não possui apólices disponíveis para fazer download"
                    );
                  } else {
                    createLog({
                      description: "DOWNLOAD RELATÓRIO DE COBRANÇAS",
                      type: "VIS",
                      status: "SUCESSO",
                      filter: filter,
                      table: "Billing",
                    });
                  }
                }}
                className={
                  "btn btn-danger link-button-list" +
                  (document == null ? " disabled" : "")
                }
                filename={"relatorio-cobrancas.csv"}
                data={document || ""}
                headers={columns}
              >
                <FontAwesomeIcon icon={faDownload} /> Download .CSV
              </CSVLink>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Label className="label-filter">Status</Form.Label>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={2}>
          <Form.Check
            className="checkbox-filter"
            type="checkbox"
            label="Pago"
            name="PAGO"
            checked={checkboxValue.PAGO}
            onChange={handleCheckboxChange}
          />
        </Col>
        <Col sm={2}>
          <Form.Check
            className="checkbox-filter"
            type="checkbox"
            label="Aberto"
            name="ABERTO"
            checked={checkboxValue.ABERTO}
            onChange={handleCheckboxChange}
          />
        </Col>
        <Col sm={2}>
          <Form.Check
            className="checkbox-filter"
            type="checkbox"
            label="Fechado"
            name="FECHADO"
            checked={checkboxValue.FECHADO}
            onChange={handleCheckboxChange}
          />
        </Col>
      </Row>
    </FilterBar>
  );
};

export default Filter;
