import lottie from "lottie-web/build/player/lottie_light";
import { useEffect } from "react";
import Show from "../ShowIf";


// ASSETS
import GuidoFloating from '../../assets/animations/guido-floating.json';

const LoadingScreen = props => {

    useEffect(() => {
        const elem = document.querySelector("#loading-animation");
        if(!elem?.hasChildNodes()){

            lottie.loadAnimation({
                container: elem,
                animationData: GuidoFloating,
                renderer: 'svg',
                loop: true,
                autoplay: true,
            });
        }
        
    }, [props.show]);

    return <Show if={props.show}>
            <div id="loadingScreen">
                <div id="loading-animation"></div>
            </div>
        </Show>
}

export default LoadingScreen;