import { Row, Container, Button, Col, Figure } from "react-bootstrap";
import { Link } from "react-router-dom";

// Assets
import ElefantesCoracaoPNG from '../../assets/elefantes-coracao.png';
import ElefantesQuadroPNG from '../../assets/elefantes-quadro.png';
import ClicouContratouPNG from '../../assets/clicou-contratou.png';
import PrecoJustoPNG from '../../assets/preco-justo.png';

/**
 * 
 * @param {{
 *     show: boolean
 *     onSend: Function
 *     onReenviar: Function
 * }} props
 * @returns 
 */
const Home = props => {

    return (
        <Container className="mainContent">           
            <div className="mb-7">
                <h1>Stories</h1>
                <p className="subtitle">
                    Um seguro de vida feito especialmente para pessoas que se preocupam
                </p>
            </div>

            <section className="mb-6">
                <Col sm="12"><h2 className="mb-4">Preço justo que cabe no bolso</h2></Col>
                <Row>                
                    <Col sm="3" xs="3" className="align-self-center">
                        <Figure><img src={PrecoJustoPNG} /></Figure>
                    </Col>
                    <Col sm="9" xs="9" className="align-self-center">                    
                        <p>Garanta proteção e conforto emocional dos seus amores por apenas <strong>R$13,79/mês</strong> </p>
                    </Col>
                </Row>
            </section>
       
            <section className="mb-6">
                <Col sm="12"><h2 className="mb-4">Coberturas para você</h2></Col>
                <Row className="mb-4">                
                    <Col sm="8" xs="9" className="align-self-center">    
                        <h3>Morte Por Acidente</h3>                
                        <p>
                            No caso de morte por acidente seus beneficiários recebem <strong>R$ 15.000,00</strong> (aumente ou diminua o valor a qualquer momento pela app) 
                            dividido conforme as porcentagens que você definir no app.
                        </p>
                    </Col>
                    <Col sm="4" xs="3" className="align-self-center">
                        <Figure><img src={ElefantesCoracaoPNG} /></Figure>
                    </Col>                    
                </Row>
                <Row className="mb-4">                
                    <Col sm="4" xs="3" className="align-self-center">
                        <Figure><img src={ElefantesQuadroPNG} /></Figure>
                    </Col>                    
                    <Col sm="8" xs="9" className="align-self-center">    
                        <h3>Stories (Caixinhas)</h3>                
                        <p>
                            Seguro de vida é sobre <strong>amor</strong>. Sobre quem queremos amparar, se possível, para sempre.
                            Pensando nisso, o Stories convida você a transcender o seu <strong>amor</strong>.
                            Encante-se com a possibilidade de celebrar sua história e registrá-la em forma de mensagens, vídeos, áudios ou fotos.
                            Todas essas lembranças só serão compartilhadas com seus beneficiários após você virar estrela. 🥰
                        </p>
                    </Col>
                </Row>                             
            </section>    

            <section>
                <Col sm="12"><h2 className="mb-4">Alterações imediatas</h2></Col>
                <Row>     
                    <Col sm="8" xs="9" className="align-self-center">                    
                        <p>
                            Clicou, contratou, está valendo. Pausou, está pausado. Quer mudar a cobertura? Move-se um cursor e está feito. 
                            Nasceu o bebê? Está incluso no seguro. Quer cancelar? Sim, senhor(a). 
                            Tudo estará vigente no segundo seguinte ao clicar no botão confirmar.
                        </p>
                    </Col>
                    <Col sm="4" xs="3" className="align-self-center">
                        <Figure><img src={ClicouContratouPNG} /></Figure>
                    </Col>                    
                </Row>
            </section>   

            <Row className="justify-content-center mb-6">
                <a href="https://www.emotion.com.br/condicoes-gerais" target="_blank">Condições gerais</a>
            </Row>

            <Row className="justify-content-md-center text-center">
                <Col sm="12" sm="auto">
                    <Link className="btn btn-primary" to="cadastro">
                        Contratar
                    </Link>
                </Col>
            </Row>                        
        </Container>            
    );
}
export default Home;