import React from "react";
import { useContext, useEffect, useState } from "react";
import LoadingContext from "../../contexts/LoadingContext";
import useLogged from "../../hooks/useLogged";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

//função principal (componente)
const TypeServiceList = () => {
  const sessionData = useLogged("/login");
  const { setLoading } = useContext(LoadingContext);
  const [document, setDocument] = useState();

  //cria array de criação do cabeçalho
  const columns = React.useMemo(() => [
    { label: "Código", key: "codigo" },
    { label: "Responsável", key: "responsavel" },
    { label: "Unidade", key: "unidade" },
    { label: "Valor", key: "valor" },
    { label: "Atenção", key: "atencao" },
    { label: "Crítico", key: "critico" },
  ]);

  //função de controle da tabela
  useEffect(() => handleSearch(), []);

  //cria cabeçalho da tabela
  const headerTable = (header) => {
    return header.map((h) => {
      return <th>{h.label}</th>;
    });
  };
  //cria dados da tabela
  const cellsTable = (cells) => {
    return cells?.map((c) => {
      return (
        <tr>
          <td>{c.codigo}</td>
          <td>{c.responsavel}</td>
          <td>{c.unidade}</td>
          <td>{c.valor}</td>
          <td>{c.atencao}</td>
          <td>{c.critico}</td>
          <td>
            <Link to={"/create-type-service/" + c.codigo}>Alterar</Link>
          </td>
        </tr>
      );
    });
  };

  //acesso a API
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await api.get("/backoffice/admin/listTypeServices");
      if (response.status === 200) {
        setDocument(response.data.data);
        console.log(document);
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar serviços. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível encontrar serviços");
    } finally {
      setLoading(false);
    }
  };

  //html
  return (
    <Row className="mb-2">
      <Col sm="6">
        <h3>Tipo de Serviços</h3>
      </Col>
      <Col md={{ span: 2, offset: 3 }}>
        <Link to="/create-type-service" className={"btn btn-danger"}>
          Cadastrar Tipo de Serviço
        </Link>
      </Col>
      <Row className="mb-2" />
      <Col sm="11">
        <Table striped borderless hover size="md">
          <thead>
            <tr>{headerTable(columns)}</tr>
          </thead>
          <tbody>{cellsTable(document)}</tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default TypeServiceList;
