import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SearchPolicies from './pages/SearchPolicies';
import DetailsPage from './pages/Details';
import Home from './pages/Home';
import LayoutPage from './pages/LayoutPage';
import PolicyList from './pages/PolicyList';
import LoginPage from './pages/Login';
import NotFoundPage from './pages/NoFoundPage';
import Dashboard from './pages/Dashboard';
import BillingList from './pages/BillingList';
import CreateTypeService from './pages/CreateTypeService';
import TypeServiceList from './pages/TypeServiceList';
import CustomerService from './pages/CustomerService';
import CreateService from './pages/CreateService';
import ServiceDetails from './pages/ServiceDetails';
import ListServices from './pages/ListServices';

const PagesRoutes = () => {
    return(
        <Router>
            <LayoutPage>
                <Switch>
                    <Route exact path="/" component={Home}>
                        <Redirect to="/login" />
                    </Route>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/search-policies" component={SearchPolicies} />
                    <Route path="/details/:documentNumber/:policy" component={DetailsPage} />
                    <Route path="/policy-list" component={PolicyList} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/billing-list" component={BillingList} />
                    <Route path="/create-type-service/:code?" component={CreateTypeService} />
                    <Route path="/type-service-list" component={TypeServiceList} />
                    <Route path="/customer-service" component={CustomerService} />
                    <Route path="/create-service/:documentNumber" component={CreateService} />
                    <Route path="/service-details/:protocol?" component={ServiceDetails} />
                    <Route path="/list-services" component={ListServices} />
                    <Route path="*" component={NotFoundPage}/>
                </Switch>
            </LayoutPage>
        </Router>
    );
}

export default PagesRoutes;