import { useState } from "react";
import { Container, Row } from "react-bootstrap"
import useLogged from "../../hooks/useLogged";
import SearchDocuments from "./components/SearchDocuments";
import Seguro from "./components/Seguro";

const SearchPolicies = () => {

    const [documents, setDocuments] = useState([]);
    const [documentNumber, setDocumentNumber] = useState("");

    const sessionData = useLogged("/login", false);

    // ========= METHODS =============
    const buildDocuments = () => {
        return documents?.sort((a,b) => a.number > b.number ? -1 : 1 ).map( (d,i) => <Seguro key={i+"-documentpolicy"} data={d} documentNumber={documentNumber} />);
    }

    /**
     * 
     * @param {any[]} documents 
     */
    const handleDocuments = (documents, docNumber) => {
        setDocuments(documents);
        setDocumentNumber(docNumber);
    }


    return (
        <div>
            
            <Container>  
                <h1 className="mb-4 text-left"> Consulte apólices </h1>  
                
                <section className="mb-7">
                    <Row>
                        <SearchDocuments onResult={handleDocuments} />
                    </Row>
                </section>

                <section className="list-documents">
                    { buildDocuments() }
                </section>
            </Container>

        </div>
    );

}

export default SearchPolicies;