import { CCol, CRow, CWidgetStatsA } from "@coreui/react";
import LoadingContext from "../../contexts/LoadingContext";
import { useContext, useEffect, useState } from "react";
import useLogged from "../../hooks/useLogged";
import { toast } from "react-toastify";
import api from "../../services/api";
import Filter from "./Filter";
import ModalCards from "./Modal";

const Dashboard = () => {
  const { setLoading } = useContext(LoadingContext);
  const sessionData = useLogged("/login", false);
  const [cards, setCards] = useState();
  const [filter, setFilter] = useState("period=7");
  const [statusModal, setStatusModal] = useState(false);
  const cardsColor = ['#FFE82A', '#F68A50', '#EE3B60', '#A42383', '#7F6EA9', '#00B0D1', '#83C3A4'];
  useEffect(() => handleSearch(), [filter]);
  const showModal = () => {
    return statusModal ? <ModalCards status={ statusModal } filter={ filter } show={ statusModal != false } handleClose={ ()=> setStatusModal(false) } /> : "";
  }

  const createCards = (widgets) => {
    return widgets?.map((w, key) => {
      let description;
      switch (w.status) {
        case 0:
          description  = "Em Onboarding";
          break;
        case 10:
          description  = "Onboarding finalizado, pronto para o Underwriting";
          break;
        case 20:
          description  = "Em Underwriting";
          break;
        case 30:
          description  = "Underwriting finalizado com sucesso";
          break;
        case 70:
          description  = "Em processo de criação da conta";
          break;
        case 80:
          description = "Criação de conta finalizada com sucesso";
          break;
        case 99:
          description  = "Erro no underwriting";
          break;
      }
      return (
        <CCol xs={key != 6  ? 6 : 12}>
        <div onClick={() => (w.count != 0 ? setStatusModal({ code: w.status, description: description }): null)}>
          <CWidgetStatsA
            style={{backgroundColor: cardsColor[key] } }
            className={"mb-3 card-dashbord"}
            title={ description }
            value={ w.count.toString() }
          />
        </div>
      </CCol>
      );
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        "backoffice/dashboard/getOnboarding?" + filter
      );
      if (response.status == 200) {
        setCards(response.data.data.opportunities);
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar as oportunidades. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível encontrar as oportunidades.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <> 
      { showModal() }
      <Filter setProps={setFilter} value={filter}/>
      <CRow className="div-cards">{createCards(cards)}</CRow>
    </>
  )
};

export default Dashboard;
