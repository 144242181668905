import { useEffect } from "react";
import { useHistory } from "react-router";
import Auth from "../auth";

/**
 * Valida se usuário está logado ou não. Caso esteja, retorna os dados
 * @param {string} redirectPath 
 * @param {boolean} condition 
 */
const useLogged = (redirectPath, condition = false) =>{
    
    const history = useHistory();
    
    // Recupera sessionData 
    const sessionData = Auth.getSession();

    // Valida casos de redirecionamento
    useEffect(() => {
        if(condition){
            if(sessionData) history.push(redirectPath);
        }else{
            if(!sessionData) history.push(redirectPath);
        }
    }, [])
    
    // Caso tenha passado pela validação e tenha sessionData
    return sessionData;
}

export default useLogged;