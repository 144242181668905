import { Accordion, Row, Col } from "react-bootstrap";

const FilterBar = (props) => {
  return (
    <Accordion>
      <Accordion.Item>
        <Accordion.Header className="bg-main-button">
          <Row className="w-100">
            <Col>
              <h3 className="color-white text-capitalize">Filtros</h3>
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
            {props.children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FilterBar;
