import { Link } from "react-router-dom";


const NotFoundPage = () => {

    return (
        <div className="container-notFound text-center">

            <section>
                <h1>Página não encontrada...</h1>
                <p className="text-center color-main mb-6">A página que você procurou não está disponível.</p>

                <Link to="/" className="btn btn-primary">VOLTAR PARA HOME</Link>
            </section>


        </div>
    );
}

export default NotFoundPage;