import { faFileAlt, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingContext from "../../../../contexts/LoadingContext";
import api from "../../../../services/api";
import { statusSeguro } from "../../../../utils";

/**
 * 
 * @param {{
 *      data: any
 * }} props 
 * @returns 
 */
const DocumentPolicy = props => {

    const { data } = props;

    const {setLoading} = useContext(LoadingContext);
  
    /**
     * Busca apólice
     */    
    const downloadPolicy = async () => {
        try {
            setLoading(true);

            const response = await api.post('/backoffice/policy/getPdf', {
                documentNumber: data.documentNumber,
                policyNumber: data.number
            }, {cache: false, responseType: 'arraybuffer'});

            setLoading(false);

            if (response.status == 200) {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            } else {
                toast.warning("Apólice indisponível, por favor tente novamente mais tarde");
            };
        } catch (err) {
            setLoading(false);
            toast.warning("Apólice indisponível, por favor tente novamente mais tarde");
        }        
    }

    return (
        <section className="seguro-item bg-white mb-3">
            <Row>
                <Col sm="12" md="6">
                    <h2 className="mb-3">{data.productName} Nº {data.number}</h2>
                    <ul className="list-unstyled">
                        <li><strong>Status:</strong> { statusSeguro(data.status)} </li>
                        <li><strong>Segurado:</strong> {data.policyHolderName} </li>
                        <li><strong>Vigência:</strong> de <Moment format="DD/MM/YYYY" date={data.beginEffectiveDate} /> a <Moment format="DD/MM/YYYY" date={data.endEffectiveDate} /> </li>
                        {
                            data.status == "CAN" 
                            ? <li><strong>Dia do cancelamento:</strong> <Moment format="DD/MM/YYYY" date={data.cancellationDate} /> </li>
                            : null  
                        }
                        
                    </ul>
                </Col>

                <Col sm="12" md="6" className="p-0 d-flex align-items-center">
                    <Row className="m-0 w-100">
                        <Col className="text-center">
                        </Col>
                        <Col className="text-center">
                            <h5 className="mb-3">Baixar Apólice</h5>
                            <a onClick={downloadPolicy} target="_blank">
                                <FontAwesomeIcon className="h1 color-main" icon={faPrint} />
                            </a>
                        </Col>
                        <Col className="text-center">
                            <h5 className="mb-3">Detalhes</h5>
                            <Link to={{pathname: "details/"+data.documentNumber+"/"+data.number}}>
                                <FontAwesomeIcon className="h1 color-main" icon={faFileAlt} />
                            </Link>
                        </Col>
                    </Row>
                </Col>
              
            </Row>
        </section>
    );

}
export default DocumentPolicy;
