import React from "react";
import { useContext, useEffect, useState } from "react";
import LoadingContext from "../../contexts/LoadingContext";
import useLogged from "../../hooks/useLogged";
import moment from "moment";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import api from "../../services/api";
import { Row, Table } from "react-bootstrap";
import Moment from "react-moment";
import Filter from "./Filter";

//função principal (componente)
const BillingList = () => {
  //inicializadores do componente
  const sessionData = useLogged("/login", false);
  const { setLoading } = useContext(LoadingContext);
  const [document, setDocument] = useState();
  const [filter, setFilter] = useState({ date :"period=0" });

  //cria array de criação do cabeçalho
  const columns = React.useMemo(() => [
    { label: "Status", key: "Status" },
    { label: "Prêmio Total", key: "TotalPremium" },
    { label: "Data de Criação", key: "CreatedAt" },
    { label: "Data de Expiração", key: "ExpirationDate" },
  ]);

  //função de controle da tabela
  useEffect(() => handleSearch(), [filter]);

  //cria cabeçalho da tabela
  const headerTable = (header) => {
    return header.map((h) => {
      return <th>{h.label}</th>;
    });
  };
  //cria dados da tabela
  const cellsTable = (cells) => {
    return cells
      ?.sort((a, b) =>
        moment(a.beginEffectiveDate).isSameOrBefore(b.beginEffectiveDate)
          ? -1
          : 1
      )
      .map((c) => {
        return (
          <tr>
            <td>{c.Status}</td>
            <td><NumberFormat
                displayType={"text"}
                prefix="R$ "
                decimalSeparator=","
                value={c.TotalPremium}
              /></td>
            <td>
              <Moment format="DD/MM/YYYY HH:mm:ss" date={c.CreatedAt} />
            </td>
            <td>
              <Moment format="DD/MM/YYYY HH:mm:ss" date={c.ExpirationDate} />
            </td>
          </tr>
        );
      });
  };

  //acesso a API
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await api.get("/backoffice/dashboard/getBillingList?" + filter.date + (filter.status ? filter.status : ""));
      if (response.status == 200) {
        setDocument(response.data.data.invoices);
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar cobranças. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível encontrar cobranças");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateLog = async (params) => {
    try {
      setLoading(true);
      const response = await api.post("/backoffice/comum/log", {
        params,
      });
      if (response.status !== 201) {
        setLoading(false);
        toast.warning("Não foi possível registrar log.");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível registrar log.");
    } finally {
      setLoading(false);
    }
  };

  //html
  return (
    <div>
      <Row className="mb-2">
        <h3>Relatório de Cobranças</h3>
      </Row>
      <Filter setFilter={setFilter} filter={filter} document={document} columns={columns} createLog={handleCreateLog} />
      <Row className="mb-2"></Row>
      <Table striped borderless hover size="md">
        <thead>
          <tr>{headerTable(columns)}</tr>
        </thead>
        <tbody>{cellsTable(document)}</tbody>
      </Table>
    </div>
  );
};

export default BillingList;
