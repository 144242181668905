import moment from "moment";

export function validarCPF(cpf) {
    // Limpa cpf
    cpf = cpf.replace(/(\.|\-)/g,'');

    let soma = 0;
    let resto;

    if (cpf == "00000000000") return false;

    for (let i=1; i<=9; i++) soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11))  resto = 0;
    if (resto != parseInt(cpf.substring(9, 10)) ) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11))  resto = 0;
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false;
    return true;
}

/**
 * Retorna idade em anos e dias
 * @param {String | Date} dt Data de nascimento
 * @returns {String} Anos e data nascimento
 */
export function idadeCompleta(dt){
    const hoje = moment();
    const nascimento = moment(dt);

    // Verifica se já fez aniversario esse nao
    const dataComparacao = moment(dt).set('year', new Date().getFullYear());
    const ultimoAniversio = dataComparacao.isBefore(hoje) 
        ? dataComparacao
        : dataComparacao.set('year', (new Date().getFullYear()-1));

    const dias = hoje.diff(ultimoAniversio, 'days');
    const anos = hoje.diff(nascimento, 'years');
    
    return `${anos} anos e ${dias} dia(s)`;

}

export function statusSeguro(status){
    let s = "";
    switch(status){
        case "VIG":
            s = "Ativo";
            break;
        case "CAN":
            s = "Cancelado";
            break;
        case "REC":
            s = "Recusado";
            break;
        default:
            s = status;
            break;
    }

    return s;
}

export function formatarCPF(cpf){
    return !cpf || isNaN(cpf) 
        ? cpf
        : cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4' );
}

export function formatarCelular(numero){
    return !numero || isNaN(numero) 
        ? numero
        : numero.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3' );
}