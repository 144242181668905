import React from "react";
import { useContext, useEffect, useState } from "react";
import LoadingContext from "../../contexts/LoadingContext";
import useLogged from "../../hooks/useLogged";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import NumberFormat from "react-number-format";

//função principal (componente)
const ListServices = () => {
  const sessionData = useLogged("/login");
  const { setLoading } = useContext(LoadingContext);
  const [document, setDocument] = useState();

  //cria array de criação do cabeçalho
  const columns = [
    { label: "Protocolo", key: "protocolo" },
    { label: "Nº da Apólice", key: "numeroApolice" },
    { label: "CPF", key: "cpf" },
    { label: "Status", key: "status" },
    { label: "Atendente", key: "atendente" },
    { label: "Tipo de Atendimento", key: "tipo" },
    { label: "Início do Atendimento", key: "inicioAtendimento" }
  ];

  //função de controle da tabela
  useEffect(() => handleSearch(), []);

  //cria cabeçalho da tabela
  const headerTable = (header) => {
    return header.map((h) => {
      return <th>{h.label}</th>;
    });
  };
  //cria dados da tabela
  const cellsTable = (cells) => {
    return cells?.map((c) => {
      return (
        <tr>
          <td>{c.protocolo}</td>
          <td>{c.numeroApolice}</td>
          <td><NumberFormat displayType={"text"} format="###.###.###-##" value={c.cpf} /></td>
          <td>{c.status}</td>
          <td>{c.atendente}</td>
          <td>{c.tipo}</td>
          <td><Moment format="DD/MM/YYYY HH:mm:ss" date={c.inicioAtendimento} /></td>
          <td>
            <Link to={"/service-details/" + c.protocolo}>Detalhes</Link>
          </td>
        </tr>
      );
    });
  };

  //acesso a API
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await api.get("/backoffice/customerService/listServices");
      if (response.status === 200) {
        setDocument(response.data.data);
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar atendimentos. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível encontrar atendimentos");
    } finally {
      setLoading(false);
    }
  };

  //html
  return (
    <div> 
      <Row className="mb-2">
          <h3>Lista de Atendimentos</h3>
      </Row>
      <Row className="mb-2"></Row>
      <Table striped borderless hover size="md">
        <thead>
          <tr>{headerTable(columns)}</tr>
        </thead>
        <tbody>{cellsTable(document)}</tbody>
      </Table>
    </div>
  );
};

export default ListServices;
