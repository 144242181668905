import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import LoadingContext from "../../contexts/LoadingContext";
import api from "../../services/api";
import Show from "../../components/ShowIf";
import { Link, useHistory } from "react-router-dom";

const CustomerService = () => {
  const history = useHistory();
  const { loading, setLoading } = useContext(LoadingContext);
  const [documentNumber, setDocumentNumber] = useState("");
  const [searchType, setSearchType] = useState({
    placeholder: "Digite o CPF",
    format: "###.###.###-##",
    mask: "_",
    checked: "cpf",
  });
  const [customerData, setCustomerData] = useState();
  const [atendimentos, setAtendimentos] = useState([]);
  const columns = [
    { label: "Protocolo", key: "protocolo" },
    { label: "Nº da Apólice", key: "numeroApolice" },
    { label: "Status", key: "status" },
    { label: "Tipo de Serviço", key: "tipo" },
    { label: "Início do Atendimento", key: "inicioAtendimento" },
  ];

  // ====== METHODS ===========
  const handleChangeType = (e) => {
    if (e.target.value === "cpf") {
      setSearchType({
        placeholder: "Digite o CPF",
        format: "###.###.###-##",
        mask: "_",
        checked: "cpf",
      });
      setDocumentNumber("");
    } else if (e.target.value === "protocol") {
      setSearchType({
        placeholder: "Digite o Nº de protocolo",
        checked: "protocol",
        format: "#################",
        mask: "_",
      });
      setDocumentNumber("");
    }
  };

  const handleChange = ({ value }) => {
    setDocumentNumber(value);
  };

  //busca historico de protocolos do cpf infomado
  const handleSearchService = async () => {
    try {
      if (loading) return;

      setLoading(true);

      const atendimento = await api.get(
        "/backoffice/customerService/listServicesByDocNumber?documentNumber=" +
          documentNumber
      );

      setLoading(false);

      if (atendimento.status == 200) {
        setAtendimentos(atendimento.data.data);
      } else {
        toast.warning(
          "Não foi possível encontrar login. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível recuperar documentos");
    }
  };

  //Busca por Documento ou Protocolo
  const handleSearch = async () => {
    try {
      var consult = {};
      //valida campos
      if (searchType.checked === "cpf" && documentNumber.length != 11)
        return toast.warning("Insira o CPF completo");

      if (searchType.checked === "protocol" && documentNumber.length != 17)
        return toast.warning("Insira o Número de protocolo completo");

      //prepara o tipo de busca
      if (searchType.checked === "cpf") {
        consult = {
          url: "/backoffice/policy/getPolicyHolder",
          data: { documentNumber },
          message:
            "Não foi possível recuperar titular da apólice. Por favor, tente novamente mais tarde",
        };
      } else if (searchType.checked === "protocol") {
        consult = {
          url: "/backoffice/customerService/getService",
          data: { protocol: documentNumber },
          message:
            "Não foi possível recuperar titular da apólice. Por favor, tente novamente mais tarde",
        };
      }

      if (loading) return;

      setLoading(true);
      //consulta endpoint
      const response = await api.post(consult.url, consult.data);
      setLoading(false);
      if (response.status == 200) {
        if(searchType.checked === "cpf") setCustomerData(response.data.data);
        if(searchType.checked === "protocol" && response.data.data){
          history.push("/service-details/" + documentNumber);
        }
        else if(searchType.checked === "protocol" && !response.data.data){
          toast.warning("Não foi possivel encontrar atendimento com esse número de protocolo");
        }        
      } else {
        toast.warning(consult.message);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning(consult.message);
    }
  };

  //cria cabeçalho da tabela
  const headerTable = (header) => {
    return header.map((h) => {
      return <th>{h.label}</th>;
    });
  };

  //cria dados da tabela
  const cellsTable = (cells) => {
    return cells?.map((c) => {
      return (
        <tr>
          <td>{c.protocolo}</td>
          <td>{c.numeroApolice}</td>
          <td>{c.status}</td>
          <td>{c.tipo}</td>
          <td>
            <Moment format="DD/MM/YYYY HH:mm:ss" date={c.inicioAtendimento} />
          </td>
          <td><Link to={"/service-details/" + c?.protocolo}>Detalhes</Link></td>
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Atendimento</h3>
      <Row className="mb-2">
        <Col sm="12" md="6">
          <InputGroup
            className={["input-search", loading ? "disabled" : ""]}
            hasValidation
          >
            <NumberFormat
              value={documentNumber}
              placeholder={searchType.placeholder}
              format={searchType.format}
              mask={searchType.mask}
              customInput={Form.Control}
              aria-describedby="inputGroupPrepend"
              onValueChange={handleChange}
              disabled={loading}
              required
            />
            <Button
              onClick={() => {
                handleSearch();
                handleSearchService();
              }}
              variant="outline-secondary"
              type="button"
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          <InputGroup>
            <Form.Check
              className="radioButon-dashbord"
              type="radio"
              checked={searchType.checked === "cpf"}
              name="optionSearch"
              value="cpf"
              label="CPF"
              onChange={(e) => handleChangeType(e)}
            />
            <Form.Check
              className="radioButon-dashbord"
              type="radio"
              checked={searchType.checked === "protocol"}
              name="optionSearch"
              value="protocol"
              label="Protocolo"
              onChange={(e) => handleChangeType(e)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Show if={customerData}>
        <section className="list-documents seguro-item bg-white mb-3">
          <Row>
            <Col sm="9">
              <ul className="list-unstyled">
                <li>
                  <strong>Nome:</strong> {customerData?.name}
                </li>
                <li>
                  <strong>CPF:</strong>{" "}
                  <NumberFormat
                    displayType={"text"}
                    format="###.###.###-##"
                    value={customerData?.documentNumber}
                  />
                </li>
                <li>
                  <strong>Email:</strong>
                  {" " + customerData?.email}
                </li>
                <li>
                  <strong>Telefone:</strong>
                  <NumberFormat
                    displayType={"text"}
                    format=" (##) #####-####"
                    value={customerData?.mobile}
                  />
                </li>
              </ul>
            </Col>
            <Col sm="2" className="p-0 d-flex align-items-center">
              <Link to={"/create-service/" + customerData?.documentNumber}>
                Novo Atendimento
              </Link>
            </Col>
          </Row>
        </section>
      </Show>
      <Show if={customerData}>
        <Table borderless hover size="md">
          <thead>
            <tr>{headerTable(columns)}</tr>
          </thead>
          <tbody>{cellsTable(atendimentos)}</tbody>
        </Table>
      </Show>
    </>
  );
};

export default CustomerService;
