import { useState } from "react";
import { Container, Form, Row, Col, FloatingLabel, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Auth from "../../auth";
import useLogged from "../../hooks/useLogged";
import apiAuth from "../../services/apiAuth";

const LoginPage = () => {

    const [loginInfo, setLogin] = useState({});
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useLogged("/search-policies", true);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setLogin({...loginInfo, [name]: value});
    }

    const handleAuthenticate = async (event) => {
        try{
            setLoading(true);

            // Impede envio
            event.preventDefault();
            event.stopPropagation();

            const response = await apiAuth.post('/backoffice/user/authenticate', {
                login: loginInfo.username,
                password: loginInfo.password
            });

            setLoading(false);
    
            if(response.status == 200){
                const sessionData = {
                    ...response.data.data,
                    username: loginInfo.username
                };

                Auth.saveSession(sessionData);
                history.push("/search-policies");
    
            }else{
                toast.warning("Não foi possível realizar login. Por favor, tente novamente mais tarde");
            }
    
        }catch(err){
            setLoading(false);
            toast.warning("Não foi possível realizar login. Por favor, tente novamente mais tarde");
        }
    }

    return (
        <Container className="mainContent">
            
            <h1 className="mb-7"> Login Backoffice </h1>
            <Form noValidate onSubmit={handleAuthenticate}>
                <Row className="justify-content-md-center text-center">
                    <Col md="6">
                        <FloatingLabel controlId="floatingUsername" label="Usuário" className="col-md-12 mb-4" >
                            <Form.Control required type="text" name="username" placeholder="Usuário" onChange={handleInputChange} />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="justify-content-md-center text-center">
                    <Col md="6">
                        <FloatingLabel controlId="floatingPass" label="Senha" className="col-md-12 mb-5" >
                            <Form.Control autoComplete="off" required type="password" name="password" placeholder="Senha" onChange={handleInputChange} />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="justify-content-md-center text-center">
                    <Col md="4">
                        <Button variant="primary" type="submit" disabled={loading}>                            
                            {loading ? <Spinner animation="grow" /> :  "Conectar" }                            
                        </Button>
                    </Col>
                </Row>
            </Form>

        </Container>
    );

}

export default LoginPage;