const SESSION_ITEM = 'sessionData';

const Auth = {


    /**
     * Salva sessionData de usuario logado
     * @param {any} sessionData Informações do usuario para serem salvas
     */
    saveSession: (sessionData) => {
        localStorage.setItem(SESSION_ITEM, JSON.stringify(sessionData));
    },

    /**
     * Retorna informações de usuário salvo
     * @returns Objeto de usuario logado
     */
    getSession: () => {
        const sessionData = localStorage.getItem(SESSION_ITEM);
        return sessionData ? JSON.parse(sessionData) : false;
    },

    /**
     * Destroi sessão salva
     */
    destroySession: () => {
        localStorage.removeItem(SESSION_ITEM);
    }


}

export default Auth;