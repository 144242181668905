import axios from "axios";
import Auth from "../auth";
import config from "../config.json";

const api = axios.create({
    baseURL: config.ambiente === "LOCAL" ? config.apiFrontLocal : config.apiFront,
    headers: { 
        'Content-Type': 'application/json'
    }
});

api.interceptors.request.use(function (config) {
    const { token } = Auth.getSession();
    config.headers.Authorization = token;

    return config;
});

export default api;