import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalConfirmation = (props) => {
  //html
  return (
    <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Atenção
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deseja Realmente {props.status?.acao} Atendimento?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Não</Button>
          <Button onClick={() => props.handleUpdate(props.status.valor)}>Sim</Button>
        </Modal.Footer>
      </Modal>
  );
};

export default ModalConfirmation;
