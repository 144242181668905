import React from "react";
import { useContext, useEffect, useState } from "react";
import LoadingContext from "../../contexts/LoadingContext";
import useLogged from "../../hooks/useLogged";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Col, Row, Button } from "react-bootstrap";
import Moment from "react-moment";
import { useParams, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import Show from "../../components/ShowIf";
import ModalConfirmation from "./ModalConfirmation";

//função principal (componente)
const ServiceDetails = () => {
  const { protocol } = useParams();
  const history = useHistory();
  const sessionData = useLogged("/login");
  const { setLoading } = useContext(LoadingContext);
  const [document, setDocument] = useState();
  const [status, setStatus] = useState();
  const [modalShow, setModalShow] = useState(false);
  //função de controle da tabela
  useEffect(() => handleSearch(), []);

  //acesso a API
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await api.post(
        "/backoffice/customerService/getService",
        { protocol }
      );
      if (response.status === 200 && response.data.data) {
        setDocument(response.data.data);
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível encontrar atendimento. Por favor, tente novamente mais tarde"
        );
        history.push("/customer-service");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível encontrar atendimento");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (tipo) => {
    try {
      setLoading(true);
      const response = await api.post(
        "/backoffice/customerService/updateService",
        {
          protocol: document?.protocolo,
          type: tipo,
          details: document.detalhes,
          contactMobile: document.celularContatante,
          contactName: document.nomeContatante,
          status: status.value,
        }
      );
      if (response.status === 201) {
        toast.success("Status do atendimento atualizado com sucesso");
        history.push("/customer-service");
      } else {
        setLoading(false);
        toast.warning(
          "Não foi possível atualizar status do atendimento. Por favor, tente novamente mais tarde"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.warning("Não foi possível atualizar status do atendimento");
    } finally {
      setLoading(false);
    }
  };

  //html
  return (
    <>
      <Row className="mb-2">
        <h3>Detalhes do Atendimento</h3>
      </Row>
      <Row className="mb-2">
        <Col sm="9">
          <ul className="list-unstyled">
            <li>
              <strong>Protocolo: </strong>
              {document?.protocolo}
            </li>
            <li>
              <strong>Apólice: </strong>
              {document?.numeroApolice}
            </li>
            <li>
              <strong>CPF: </strong>
              <NumberFormat
                displayType={"text"}
                format="###.###.###-##"
                value={document?.cpf}
              />
            </li>
            <li>
              <strong>Nome do Contatante: </strong>
              {document?.nomeContatante}
            </li>
            <li>
              <strong>Telefone do Contatante: </strong>
              <NumberFormat
                displayType={"text"}
                format=" (##) #####-####"
                value={document?.celularContatante}
              />
            </li>
            <li>
              <strong>Tipo do Atendimento: </strong>
              {document?.tipo}
            </li>
            <li>
              <strong>Status: </strong>
              {document?.status}
            </li>
            <li>
              <strong>Atendente: </strong>
              {document?.atendente}
            </li>
            <li>
              <strong>Data Início do Atendimento: </strong>
              <Moment
                format="DD/MM/YYYY HH:mm:ss"
                date={document?.inicioAtendimento}
              />
            </li>
            <li>
              <strong>Data conclusão do Atendimento: </strong>
              {document?.conclusaoAtendimento ? (
                <Moment
                  format="DD/MM/YYYY HH:mm:ss"
                  date={document?.conclusaoAtendimento}
                />
              ) : (
                "-"
              )}
            </li>
            <li>
              <strong>Detalhes do Atendimento: </strong>
              {document?.detalhes}
            </li>
          </ul>
        </Col>
      </Row>
      <Show if={!document?.conclusaoAtendimento}>
        <Row>
          <Col>
            <Button
              onClick={() => {
                setModalShow(true);
                setStatus({ acao: "Cancelar" , value: "Cancelado" });
              }}
            >
              {"Cancelar Atendimento"}
            </Button>
            {" "}
            <Button
              onClick={() => {
                setModalShow(true);
                setStatus({ acao: "Concluir" , value: "Concluído" });
              }}
            >
              {"Concluir Atendimento"}
            </Button>
          </Col>
        </Row>
      </Show>
      <ModalConfirmation handleUpdate={handleUpdate} status={status} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default ServiceDetails;
